import React, { Component } from "react";
import "./carbonads.css";

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const affiliates = [{
  href: "https://www.scraperapi.com/?fp_ref=box-piper-app",
  imageSrc: "/assets/scraperapi-1.gif",
  alt: "scraperapi"
}, {
  href: "https://scrapeops.io/?fpr=boxpiperapp",
  imageSrc: "/assets/scraperops-1.png",
  alt: "scraperops"
}, {
  href: "https://testimonial.to/?via=boxpiper",
  imageSrc: "/assets/testimonial-io.png",
  alt: "testimonial.io"
},
{
  href: "https://www.apify.com?fpr=lblmn",
  imageSrc: "/assets/apify.png",
  alt: "apify"
}, {
  href: "https://www.scrapingbee.com?fpr=box-piper-app53",
  imageSrc: "/assets/scrapingbee.png",
  alt: "scrapingbee"
}, {
  href: "https://rightmessage.com?fp_ref=box82",
  imageSrc: "/assets/rightmessage.png",
  alt: "rightmessage"
},


]
class carbonAds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customClass: this.props.customClass,
      carbonUrl:
        "https://cdn.carbonads.com/carbon.js?serve=CEBI52QL&placement=boxpipercom",
      aSync: true,
    };
  }

  componentDidMount() {
    const carbon_wrapper = document.querySelector(
      `.${this.state.carbonWrapper
        ? this.state.carbonWrapper
        : "carbon-adds-wrapper"
      }`
    );
    const script = document.createElement("script");
    script.src = this.state.carbonUrl;
    script.async = this.state.aSync;
    script.id = "_carbonads_js";
    carbon_wrapper.appendChild(script);
  }

  render() {
    const showRandom = getRandomInt(affiliates.length)
    return (
      <>
        <div className={`carbon-adds-wrapper ${this.state.customClass}`}></div>
        {/* <div className={`affiliates-wrapper`} style={{ marginTop: "20px" }}>
          {[affiliates[showRandom]].map((a, index) => {
            return <a href={a.href} target="_blank" key={index}>
              <img src={a.imageSrc} alt={a.alt} width={"100%"} height={"100%"} />
            </a>
          })}
        </div> */}
      </>

    );
  }
}

export default carbonAds;