import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import addToMailchimp from "gatsby-plugin-mailchimp";
import React from "react";
import { Sparkles } from "../Sparkle";

export default class MailChimpForm extends React.Component {
  constructor() {
    super();
    this.state = { email: "", result: null };
  }
  _handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email);
    this.setState({ result: result });
  };
  handleChange = (event) => {
    this.setState({ email: event.target.value });
  };
  render() {
    return this.state.result?.result === "success" ? (
      <div style={{ color: "white" }}>
        Congrats, you're newsworthy. Thanks for subscribing 🎉. Add
        boxpiperapp@gmail.com to your contact list, so you won't miss any email.
        Do check your Inbox, Promotions, Social, Updates section of your inbox.
        Mail will be delivered in any one of them. Mostly in the Promotions
        section.
      </div>
    ) : this.state.result?.result === "error" ? (
      <div style={{ color: "white" }}>Seems you are already subscribed 🥝</div>
    ) : (
      <div>
        <b style={{ color: "white" }}>
          Subscribe to get more such interesting content !
        </b>
        <form onSubmit={this._handleSubmit} style={{ marginTop: "10px" }}>
          <Input
            type="text"
            autoComplete="email"
            type="email"
            name="email"
            onChange={this.handleChange}
            placeholder="Your Cool Email Address"
            fullWidth
            color="primary"
            style={{
              width: "100%",
              borderRadius: "24px",
            }}
            inputProps={{
              style: {
                background: "white",
                textAlign: "center",
              },
            }}
            required
          ></Input>
          <Button
            variant="contained"
            color="primary"
            label="Submit"
            type="submit"
            style={{ width: "100%", borderRadius: "10px", marginTop: "10px" }}
          >
            <Sparkles>
              <Typography variant="button">Subscribe</Typography>
            </Sparkles>
          </Button>
        </form>
      </div>
    );
  }
}
