import { trackCustomEvent } from "gatsby-plugin-google-analytics";

export function trackEvent(e, category, action, label) {
  //   e.preventDefault();
  trackCustomEvent({
    category: category,
    action: action,
    label: label,
  });
}
